@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');


/*********************************************************************************/

p {
    margin-top: 0;
    margin-bottom: 0;

}


#footer {
    background-color: #061b3d;
    color: gray;
    z-index: 1000;
}

.btn-style {
    font-size: 1.25rem;
}

.home-style {
    font-size: 1.75rem;
}

@keyframes move {
  from { top: -80px;}
  to   { top: 0; }
}



.progress {
    height: 1.5rem;
    font-size: 1rem;
}

.testbg { 
    background-image: url("../img/NuBie.png");
    height: 400px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aguabg { 
    background-image: url("../img/FastandFresh.png");
    height: 400px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.todobg { 
    background-image: url("../img/todolist.png");
    height: 400px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.mytext {
    font-size: 1.2rem;
}

.btn-style:hover {
    cursor: pointer;
}
.home-style:hover {
    cursor: pointer !important;
}




@keyframes slideBot {
    0% {opacity: 0; transform: translateY(300px);}
    100% {opacity: 1; transform: translateY(0)}
}
.hidden, .hiddenBot, .hiddenRight {
    //opacity: 0;
}
.slideBot {
    animation: slideBot 1s;
}
@keyframes slideLeft {
    0% {opacity: 0; transform: translateX(-300px);}
    100% {opacity: 1; transform: translateX(0)}
}
@keyframes slideRight {
    0% {opacity: 0; transform: translateX(300px);}
    100% {opacity: 1; transform: translateX(0)}
}

.slideRight {
    animation: slideRight 1s;
}

.slideLeft {
    animation: slideLeft 1s;
}

.project-container {
    padding-top: 1rem;
}


@keyframes fillBar {
    0% {width: 0;}
    100% {width: 80%;}
}
@keyframes fill60Bar {
    0% {width: 0;}
    100% {width: 60%;}
}
@keyframes fill90Bar {
    0% {width: 0;}
    100% {width: 90%;}
}

.fill-bar {
    width: 0;
}

.filed-80-bar {
    animation: fillBar 2s;
}
.fill-60-bar {
    width: 0;
}

.filed-60-bar {
    animation: fill60Bar 2s;
}
.fill-90-bar {
    width: 0;
}

.filed-90-bar {
    animation: fill90Bar 2s;
}


.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #f8f9fa;
}

.text {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  text-align: center;
}

.project-button {
    background-color: #f8f9fa;
    color: black;
    border: 1px #122444 solid;
    font-size: 20px;
    position: absolute;
    top: 70%;
    left: 50%;
    -webkit-transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%);
    text-align: center;
} 
.project-button:hover {
    background-color: #122444;
    color: white;
}

.aguabg:hover .overlay, .testbg:hover .overlay, .todobg:hover .overlay  {
    opacity: 1;
}



//overlay for gallery
#gallery-card {
    position: fixed;
    display: none;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 100;
}

.modal-card {
    display: none;
    position: absolute;
    width: 45%;
    top: 10%;
    left: 50%;
    height: 85%;
    background-color: #f8f9fa;
    z-index: 101;
    transform: translate(-50%,-10%);
    -ms-transform: translate(-50%,-10%);
}

.visual video,.visual img {
    width: 100%;
    height: auto;
    border-bottom: 2px solid black;
}

.modal-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5rem;
}

.modal-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
